<template>
    <span ref="links">
        <template v-for="(route, index) in routes">
            {{ index !== 0 ? linkSeparator : '' }}
            <router-link tag="a" :key="index + '-' + currentLanguageCode" :to="'/'+currentLanguageCode+route.path" v-slot="{ href, isExactActive }">
                <a v-if="route.shortName" 
                        :class="'page-'+route.shortName+(linkCssClass ? ' '+linkCssClass : '')+(isRouteActive(isExactActive, route) ? ' '+activeCssClass : '')"
                        :data-anchor-path="getAnchorPath(route)"
                        :href="href" 
                        @click="linkClick($event, route)">
                    {{ getLocalizedText(route.label) }}
                </a>
                <a v-else 
                        :class="linkCssClass" 
                        :href="route.path" 
                        @click="linkClick($event, route)">
                    {{ getLocalizedText(route.label) }}
                </a>
            </router-link>
        </template>
    </span>
</template>

<script lang="ts">
import { mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { languagesTypes } from '@fwk-client/store/types';
import LinksMixin from './LinksMixin.vue'

@Component({
  components : {
      
  },
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
        })
  }
})
export default class TextLinks extends mixins(LinksMixin) {

    /**
     * linkSeparator
     * separator between links
     */
    @Prop({
        type: String,
        required: false,
        default: '/',
    }) readonly linkSeparator!: string;
}
</script>