<template>
    <footer id="footer">

        <!-- Copyrights
        ============================================= -->
        <div id="copyrights">

            <div class="container">

                <div class="row col-mb-30">

                    <div class="col-md-6 copyright-links">
                        <img v-if="computedOptions.pictureURL" :src="computedOptions.pictureURL" :alt="$cms.utils.getComputedLabel(labels.pictureAlt)" class="footer-logo" />
                        {{ $t("footer.copyright-company", [year, $cms_site.name]) }}
                        <div>
                            <TextLinks :routes="legalRoutes"></TextLinks>
                        </div>
                    </div>

                    <div class="col-md-6 text-md-end">
                        <div class="copyrights-menu">
                            <TextLinks :routes="siteRoutes"></TextLinks>
                        </div>
                        <a v-for="(socialLink, index) in computedOptions.socialLinks" :key="socialLink.type+'_'+index" :href="socialLink.url" :class="'social-icon inline-block si-borderless mb-0 si-'+socialLink.type" target="_blank">
                            <i :class="'icon-'+socialLink.type"></i>
                            <i :class="'icon-'+socialLink.type"></i>
                        </a>
                    </div>

                </div>

                <div v-if="!computedOptions.hideCmsFooter" class="line line-sm"></div>

                <div v-if="!computedOptions.hideCmsFooter" class="row">

                    <div class="col-12 copyrights-links text-center">
                        <span v-html="$t('footer.copyright-cms', [year])"></span>
                        <div>
                            <i class="icon-envelope2"></i> <a href="mailto:contact@igot-web.com">contact@igot-web.com</a>
                        </div>
                    </div>

                </div>

            </div>

        </div><!-- #copyrights end -->

    </footer>
</template>

<style>
    #footer .footer-logo {
        margin-bottom:10px;
    }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';

import { types as applicationTypes } from '@root/src/client/store/application';
import TextLinks from '@root/src/client/components/panels/layouts/common/TextLinks.vue';
import { CmsBoolean, CmsLabel, CmsPicture } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
interface FooterOptions {
  /** @cmsType CmsPicture */
    pictureURL?:CmsPicture;
  legalLinks?:("conditions-generales"|"politique-de-confidentialite"|"cookies"|"mentions-legales")[]
  socialLinks?:any[],
  /** @cmsType CmsBoolean */
  hideCmsFooter?:CmsBoolean
}

/** @cmsLabels */
interface FooterLabels {
    /** @cmsType CmsLabel */
  pictureAlt?:CmsLabel;
}

@Component({
  components: { 
      TextLinks
  },
  // We add name property from store as a name computed
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
      ...mapState({
          isLoggedIn: (state:any) => state.authentication.isLoggedIn
        })
  }
})
export default class Footer extends Vue {

    @Prop({
        type: Object,
        required: false,
    }) readonly options!: FooterOptions | undefined

    computedOptions:FooterOptions = {
        legalLinks:[],
        socialLinks:[],
        hideCmsFooter:false,
        ...this.options,
        pictureURL : (this.options && this.options.pictureURL) ? this.$cms.utils.getComputedPictureURL(this.options.pictureURL) : undefined
    };

    @Prop({
        type: Object,
        required: false,
        default: () => { return {} }
    }) readonly labels!: FooterLabels

    year = (new Date()).getFullYear();

    get legalRoutes() {
        return this.computedOptions.legalLinks!.map((link:string) => {
            return {
                path: "/" + link,
                shortName: link,
                label: this.$t("menu."+link)
            }
        })
    }

    get siteRoutes() {
        // We get the routes in menu
        var siteRoutes = this.$store.getters['application/' + applicationTypes.getters.GET_ROUTES_IN_MENU](this, "footer");
        return siteRoutes;
    }
}
</script>