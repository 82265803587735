<template>
    
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import { anchorLink } from '@root/src/client/theme/canvas-664/canvas.utils';
import { types as applicationTypes, gettersPath as applicationGettersPath } from '../../../../store/application';
import { languagesTypes } from '@fwk-client/store/types';

@Component({
  components : {
      
  },
  computed: {}
})
export default class LinksMixin extends Vue {

    /**
     * activeCssClass
     * css class of element when active
     */
    @Prop({
        type: String,
        required: false,
        default: 'active',
    }) readonly activeCssClass!: string;

    /**
     * linkCssClass
     * css class of the list
     */
    @Prop({
        type: String,
        required: false,
        default: '',
    }) readonly linkCssClass!: string;
    
    /**
     * route : {
     *  path:string
     *  shortName:string
     *  label:string
     *  anchor:string
     * }
     */
    @Prop({
        type: Array,
        required: true,
    }) readonly routes!: any[]

    isRouteActive(isActive:boolean, routeMenu:any) {
        return isActive || this.$store.getters[applicationGettersPath[applicationTypes.getters.GET_IS_ROUTE_ACTIVE]](routeMenu);
    }

    getAnchorPath(route:any) {
        return (route.anchor && route.anchor.startsWith("#")) ? route.anchor : undefined;
    }

    headerOffset = 60;

    linkClick(evt:Event, route:any) {
        if(route.shortName && route.anchor) {
            // We prevent default redirection
            evt.preventDefault();

            // We first check if we need to navigate or not
            if(this.isRouteActive(false, route)) {
                // We only need to move to anchor
                anchorLink(route.anchor, this.headerOffset);
            }
            else {
                var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
                // We need to navigate to the route
                this.$router.push({
                    name : route.shortName,
                    params : {
                        lang : languageCode
                    }
                }).catch(err => {}).then(() => {
                    setTimeout(() => { // We set a timeout as we need to wait the page to be rendered
                        // Now that we are on the correct route, we trigger the anchor
                        anchorLink(route.anchor, this.headerOffset);
                    },250)
                });
            }
        }
        else if(route.shortName) {
            // We prevent default redirection
            evt.preventDefault();

            var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
                // We need to navigate to the route
                this.$router.push({
                    name : route.shortName,
                    params : {
                        lang : languageCode
                    }
                }).catch(err => {})
        }
    }

    checkActiveLink() {
        
        var nearest = undefined;
        var distance = document.body.clientHeight;
        for(var route of this.routes) {
            if(this.isRouteActive(false, route)) {
                if(route.anchor && route.anchor == "#") {
                    if(window.scrollY < distance) {
                        nearest = route;
                        distance = window.scrollY;
                    }
                }
                else if(route.anchor && route.anchor.startsWith("#")) {
                    var element = document.querySelector(route.anchor);
                    if(element) {
                        var offsetTop =  element.getBoundingClientRect().top - this.headerOffset;
                        if(offsetTop <= 0 && Math.abs(offsetTop) < distance) {
                            nearest = route;
                            distance = Math.abs(offsetTop);
                        }
                    }
                }
            }
        }

        if(nearest) {
            // We unselect current active link
            var activeItems = (this.$refs.links as HTMLElement).querySelectorAll("."+this.activeCssClass);
            if(activeItems.length > 0) { 
                activeItems.forEach((activeItem) => {
                    activeItem.classList.remove(this.activeCssClass)
                }) 
            }

            // We select the new active link
            var nearestItem = (this.$refs.links as HTMLElement).querySelector("[data-anchor-path='"+nearest.anchor+"']");
            if(nearestItem) { nearestItem.classList.add(this.activeCssClass); }
        }

    }

    mounted() {
        if(typeof window !== 'undefined') {
            // We execute it the first time
            this.checkActiveLink();
        }
    }

    created() {

        if(typeof window !== 'undefined') {
            // We add the dom event listeners
            window.addEventListener('scroll', this.checkActiveLink);
        }
    }

    destroyed() {

        if(typeof window !== 'undefined') {
            // We remove the dom event listeners
            window.removeEventListener('scroll', this.checkActiveLink);
        }
    }
}
</script>